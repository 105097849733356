// Cargando.js
import React from 'react';
import CircleLoader from 'react-spinners/CircleLoader';

const Cargando = ({ calcularResultado, establecerValoresPorDefecto, cargando }) => (
    <div className='py-7'>
        <button onClick={calcularResultado} className="bg-secondary text-black px-6 py-4 rounded hover:bg-secondary-600">Calcular</button>
        <button onClick={establecerValoresPorDefecto} className="bg-gray-300 text-black ml-3 px-6 py-4 rounded hover:bg-gray-400 ">Default</button>
        {cargando && (
            <div className="flex justify-center items-center">
                <CircleLoader color="#36d7b7" size={150} />
            </div>
        )}
    </div>
);

export default Cargando;
