//DISPONIBILDAD ENERGIA RENOVABLE
//Factor capacidad
//Volatilidad(varianza)
//VIABILIDAD UBICACION
//Proximidad a la costa
//Ubicacones oleaductos
//Ubicacion matriz energeticas:
//Ubicaciones fuentes de co2: (Planta carbon,cemento,siderurgica,fermentacion)
//Ubicaciones Plantas Desalinizadora: veremos
//Disponibidad de agua (fresca y reciclada):veremos
 //OPORTUNIDADES DE USO FINAL
//Ubicacones puertos con posibilidad de exportar:
//Proximidad a la costa
//ALMACENAMIENTO
//Estanques de combustible
//RANKING TOTAL

//AIzaSyBwiB7KOidyLVOgwjwd0ddh-FAYVSXc1Bs
import React, { useState } from 'react';

const Zonificador = ({
    onRadiusChange,
    setRadiusOleoductos,
    radiusOleoductos,
    setRadiusTransmision,
    radiusTransmision,
    setRadiusPuertos,
    radiusPuertos,
    setRadiusFuentesCo2,
    radiusFuentesCo2,
    setRadiusEstanquesCom,
    radiusEstanquesCom,

}) => {
    


    return (
        <div>
            <label className="block text-xl font-medium text-gray-700 mb-1">Zonificador</label>
            <hr className="my-2 border-secondary"/>
            <div className="grid grid-cols-3 gap-4 mb-6">
                {/* Columna 1 */}
                <div>
                    <label className="block text-lg font-medium text-gray-700 mb-1">Ubicación</label>
                    <Barra radio={radiusFuentesCo2} setRadio={setRadiusFuentesCo2} titulo="Fuentes CO2" onRadiusChange={onRadiusChange} />
                    <BarraCheckbox radio={radiusOleoductos} setRadio={setRadiusOleoductos} titulo="Oleoductos" onRadiusChange={onRadiusChange} />
                    <BarraCheckbox radio={radiusTransmision} setRadio={setRadiusTransmision} titulo="Transmisión Energética" onRadiusChange={onRadiusChange} />
                </div>
                
                {/* Columna 2 */}
                <div>
                    <label className="block text-lg font-medium text-gray-700 mb-1">Uso final</label>
                    <Barra radio={radiusPuertos} setRadio={setRadiusPuertos} titulo="Puertos" onRadiusChange={onRadiusChange} />
                   
                </div>

                {/* Columna 3 */}
                <div>
                    <label className="block text-lg font-medium text-gray-700 mb-1">Almacenamiento</label>
                    <Barra radio={radiusEstanquesCom} setRadio={setRadiusEstanquesCom} titulo="Estanques combustible" onRadiusChange={onRadiusChange} />
                </div>
            </div>
        </div>
    );
}; 

export default Zonificador;

const BarraCheckbox = ({ radio, setRadio, titulo, onRadiusChange }) => {
    // Función para manejar cambios en el checkbox
    const handleCheckboxChange = (setRadiusFunc) => (e) => {
        const isChecked = e.target.checked;
        const newRadius = isChecked ? 1 : 0;
        setRadiusFunc(newRadius);
        onRadiusChange(newRadius);
    };

    return (
        <div className="mb-4">
            <label className="block text-sm font-medium text-black mb-1">{titulo}</label>
            <input type="checkbox" className="toggle [--tglbg:#69E995] bg-white hover:bg-white border-white" checked={radio > 0} onChange={handleCheckboxChange(setRadio)} />
            <div className="text-center">{radio > 0 ? `Mostrando` : 'Oculto'}</div>
        </div>
    );
};

const Barra = ({ radio, setRadio, titulo, onRadiusChange }) => {
    const handleRadiusChange = (setRadiusFunc) => (e) => {
        const newRadius = e.target.value;
        setRadiusFunc(newRadius);
        onRadiusChange(newRadius);
    };

    return (
        <div className="mb-4">
            <label className="block text-sm font-medium text-black mb-1">{titulo}</label>
            <input type="range" min={0} max="250" value={radio} onChange={handleRadiusChange(setRadio)} className="range [--range-shdw:#69E995]" />
            <div className="text-center">{radio} km</div>
        </div>
    );
};
