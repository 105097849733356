//import logo from './logo.svg';
//import './App.css';
import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Documentation from './components/Documentacion';
import Footer from './components/Footer';
import Calculadora from './components/Cotizacion';
import MapSection from './components/MapSection';  
import { Route,Routes } from 'react-router-dom';



//Class Name react



function App() {
  const [coords, setCoords] = React.useState({
    latitud: '-52.84',
    longitud: '-70.95'
  });
  const [area, setArea] = React.useState(0);
  const [isDelimitingArea, setIsDelimitingArea] = React.useState(false);
  const [activeSection, setActiveSection] = React.useState('produccion');
  //radios
  const [radiusDisponibilidadAguaReciclada, setRadiusDisponibilidadAguaReciclada] = useState(0);
  const [radiusSubestaciones, setRadiusSubestaciones] = useState(0);
  const [radiusTransmision, setRadiusTransmision] = useState(0);
  const [radiusOleoductos, setRadiusOleoductos] = useState(0);
  const [radiusPuertos, setRadiusPuertos] = useState(0);
  const [radiusFuentesCo2, setRadiusFuentesCo2] = useState(0);
  const [radiusEstanquesCom, setRadiusEstanquesCom] = useState(0);
    
  // Estados adicionales para distancias
  const[distanciaPuerto,setDistanciaPuerto]=useState(0)
  const[distanciaMatriz,setDistanciaMatriz]=useState(0)
  const[distanciaOleoductos,setDistanciaOleoductos]=useState(0)
  const[distanciaFuentesCo2,setDistanciaFuentesCo2]=useState(0)
  const[distanciaEstanquesComb,setDistanciaEstanquesComb]=useState(0)

 



  const handleMapClick = (lat, lng,areaValue = 0) => {
    console.log("App - Área recibida:", areaValue);
    setCoords({
      
      latitud: lat.toString(),
      longitud: lng.toString(),
    });
    setArea(Math.round(areaValue));
    console.log(areaValue)
    
    
  };
  return (
    <Routes>
      <Route path="/" element={<div className="flex flex-col h-screen">
<div>
    <Navbar/>
</div>
   

<div className='flex flex-grow'>
   <div className={activeSection === 'resultados' ? "w-full"  : "w-1/2 "}>
    <Calculadora 
      latitud={coords.latitud} 
      longitud={coords.longitud} 
      area={area} 
      isDelimitingArea={isDelimitingArea} 
      setActiveSection={setActiveSection} 
      activeSection={activeSection}
      //radios de los circulos
      setRadiusDisponibilidadAguaReciclada={setRadiusDisponibilidadAguaReciclada} 
      radiusDisponibilidadAguaReciclada={radiusDisponibilidadAguaReciclada}
      setRadiusSubestaciones={setRadiusSubestaciones} 
      radiusSubestaciones={radiusSubestaciones}
      setRadiusTransmision={setRadiusTransmision}
      radiusTransmision={radiusTransmision}
      setRadiusOleoductos={setRadiusOleoductos} 
      radiusOleoductos={radiusOleoductos}
      setRadiusEstanquesCom={setRadiusEstanquesCom}
      radiusEstanquesCom={radiusEstanquesCom}
      setRadiusFuentesCo2={setRadiusFuentesCo2}
      radiusFuentesCo2={radiusFuentesCo2}
      setRadiusPuertos={setRadiusPuertos}
      radiusPuertos={radiusPuertos}
      //distancias desde el punto marcado a la variable
      distanciaPuerto={distanciaPuerto}
      distanciaOleoductos={distanciaOleoductos}
      distanciaEstanquesComb={distanciaEstanquesComb}
      distanciaFuentesCo2={distanciaFuentesCo2}
      distanciaMatriz={distanciaMatriz}

      


  
    
    />
   </div>
   <div className={activeSection !== 'resultados' ? "w-1/2 flex-2" : "hidden"}>
    <MapSection 
      onMapClick={handleMapClick} 
      setIsDelimitingArea={setIsDelimitingArea} 
      radiusDisponibilidadAguaReciclada={radiusDisponibilidadAguaReciclada}
      radiusSubestaciones={radiusSubestaciones}
      radiusTransmision={radiusTransmision}
      radiusOleoductos={radiusOleoductos}
      radiusFuentesCo2={radiusFuentesCo2}
      radiusEstanquesCom={radiusEstanquesCom}
      radiusPuertos={radiusPuertos}
      setDistanciaPuerto={setDistanciaPuerto}
      setDistanciaOleoductos={setDistanciaOleoductos}
      setDistanciaEstanquesComb={setDistanciaEstanquesComb}
      setDistanciaFuentesCo2={setDistanciaFuentesCo2}
      setDistanciaMatriz={setDistanciaMatriz}

      
      

    />
   </div>
</div>

<div>
    <Footer/>
</div>
</div>} />
      <Route path="/documentacion" element={<Documentation />} />
    </Routes>

  

  );
}

export default App;
