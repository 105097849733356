

import React from 'react';




const Produccion = ({
    combustible,
    setCombustible,
    hideHydrolysisResults,
    setHideHydrolysisResults,
    fuente,
    setFuente,
    solarMW,
    setSolarMW,
    vientoMW,
    setVientoMW,
    isDelimitingArea,
    solarPercentage,
    setSolarPercentage,
    windPercentage,
    setWindPercentage,
    longitud,
    setLongitud,
    latitud,
    setLatitud,
    areaState,
    setArea,
    calcularResultado,
    establecerValoresPorDefecto,
    cargando,
    modeloAerogenerador,
    setModeloAerogenerador,
    factorPerdidas,
    setFactorPerdidas,
    alturaSuperficie,
    setAlturaSuperficie,
    tipoArreglo,
    setTipoArreglo,
    azimut,
    setAzimut,
    inclinacionMaximaHSAT,
    setInclinacionMaximaHSAT,
    anguloElevacion,
    setAnguloElevacion,
    cobertura,
    setCobertura,
    perdidas,
    setPerdidas,
    showInfo,
    toggleInfo,
    setNumeroCiclos,
    numeroCiclos,
    setEficienciaBateria,
    eficienciaBateria,
    setSelfDischarge,
    selfDischarge,


    }) => (
    
    <div>
    <div className=" right-0 m-2  cursor-pointer" onMouseEnter={toggleInfo} onMouseLeave={toggleInfo}>
        <div className="bg-secondary ml-auto text-white rounded-full w-6 h-6 flex items-center justify-center">
        <span>ℹ️</span>
        </div>
    </div>
    {showInfo && (
           <div role="alert" onMouseEnter={toggleInfo} onMouseLeave={toggleInfo} className="alert cursor-pointer">
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
           <span>En la esquina superior izquierda del mapa puedes seleccionar "Delimitar una zona" o "Marcar un punto".</span>
             </div>
        
    )}
<label className="block text-xl font-medium text-gray-700 mb-1">Producción</label>
<hr className="my-2 border-secondary"/>
    <div className="grid grid-cols-3 gap-4 mb-6">
        {/* Columna 1 */}
        <div>
            <label className="block text-lg font-medium text-gray-700 mb-1">eFuel</label>
            <label className="block text-sm font-medium text-gray-700 mb-1">Combustible</label>
            <select value={combustible} onChange={(e) => setCombustible(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2">
                <option value="combustible1">Jet-Fuel</option>
                <option value="ethanol" disabled>🔒 Ethanol</option>
                <option value="ethanol" disabled>🔒 Methanol</option>
                <option value="ethanol" disabled>🔒 Diesel</option>

            </select>
        </div>
        

        {/* Columna 2 */}
        <div>
        <label className="block text-lg font-medium text-gray-700 mb-1">Fuente</label>
        <label className="block text-sm font-medium text-gray-700 mb-1">Fuente de energía</label>
    <select value={fuente} onChange={(e) => setFuente(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2">
        <option value="Hibrido">Híbrido</option>
        <option value="solar">Solar</option>
        <option value="viento">Viento</option>
    </select>

    {
        !isDelimitingArea && fuente === "Hibrido" && (
            <>
                <label className="block text-sm font-medium text-gray-700 mb-1">Solar MW</label>
                <input type="text" value={solarMW} onChange={(e) => setSolarMW(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2" />

                <label className="block text-sm font-medium text-gray-700 mb-1">Viento MW</label>
                <input type="text" value={vientoMW} onChange={(e) => setVientoMW(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2" />
            </>
        )
    }

    {
        !isDelimitingArea && fuente === "solar" && (
            <>
                <label className="block text-sm font-medium text-gray-700 mb-1">Solar MW</label>
                <input type="text" value={solarMW} onChange={(e) => setSolarMW(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2" />
            </>
        )
    }

    {
        !isDelimitingArea && fuente === "viento" && (
            <>
                <label className="block text-sm font-medium text-gray-700 mb-1">Viento MW</label>
                <input type="text" value={vientoMW} onChange={(e) => setVientoMW(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2" />
            </>
        )
    }
  
    {
    isDelimitingArea && (
        <>
        {fuente === "Hibrido" && (
            <>
            <label className="block text-sm font-medium text-gray-700 mb-1">Porcentaje Solar</label>
            <input
                type="number"
                value={solarPercentage}
                onChange={(e) => setSolarPercentage(e.target.value)}
                className="border border-secondary rounded w-full py-2 px-3 mb-2"
            />
            <label className="block text-sm font-medium text-gray-700 mb-1">Porcentaje Viento</label>
            <input
                type="number"
                value={windPercentage}
                onChange={(e) => setWindPercentage(e.target.value)}
                className="border border-secondary rounded w-full py-2 px-3 mb-2"
            />
            </>
        )}
        {fuente === "solar" && (
            <>
            <label className="block text-sm font-medium text-gray-700 mb-1">Porcentaje Solar</label>
            <input
                type="number"
                value={solarPercentage}
                readOnly // Hace que el input no sea editable
                className="border border-secondary rounded w-full py-2 px-3 mb-2"
            />
            </>
        )}
        {fuente === "viento" && (
            <>
            <label className="block text-sm font-medium text-gray-700 mb-1">Porcentaje Viento</label>
            <input
                type="number"
                value={windPercentage}
                readOnly // Hace que el input no sea editable
                className="border border-secondary rounded w-full py-2 px-3 mb-2"
            />
            </>
            
        )}
        </>
    )
}
        </div>

        {/* Columna 3 */}

        <div>
            <label className="block text-lg font-medium text-gray-700 mb-1">Ubicación</label>
            <label className="block text-sm font-medium text-gray-700 mb-1">Longitud</label>
            <input type="text" value={longitud} onChange={(e) => setLongitud(e.target.value)} className="border border-secondary w-full py-2 px-3 mb-2" />
            <label className="block text-sm font-medium text-gray-700 mb-1">Latitud</label>
            <input type="text" value={latitud} onChange={(e) => setLatitud(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2" />
            <label className="block text-sm font-medium text-gray-700 mb-1">Área Km2</label>
            <input type="text" value={areaState/1000000} onChange={(e) => setArea(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2" />
        </div>
        {/* Columna 4 */}
        {fuente !== "solar" && (
            <>
        {/* Columna 4 */}
        <div>
            <label className="block text-lg font-medium text-gray-700 mb-1">Aerogeneradores</label>
            <label className="block text-sm font-medium text-gray-700 mb-1">Modelo Aerogenerador </label>
            <select value={modeloAerogenerador} onChange={(e) => setModeloAerogenerador(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2">
                <option value="Siemens SWT-3.6-120">Siemens SWT-3.6-120(3600 kW, 120 m)</option>
                <option value="Sinovel SL3000/100">Sinovel SL3000/100 (3000 Kw,100 m)</option>
                <option value="Vestas V164 - 8.0 MW">Vestas V164 - 8.0 MW (8000 kw,164 m)</option>
                <option value="Acciona AW 77/1500 Class III">Acciona AW 77/1500 Class III (1500 Kw, 77 m)</option>
                <option value="SDeWind D8.2">DeWind D8.2 (2000 Kw, 80 m)</option>
                <option value="Gamesa G128-4.5 MW">Gamesa G128-4.5 MW (4500 Kw, 128 m)</option>
                <option value="Gamesa G132-5 MW">Gamesa G132-5 MW (5000 Kw, 132 m)</option>
                <option value="Sinovel SL6000/128 Onshore">Sinovel SL6000/128 Onshore (6000 Kw, 128 m)</option>
                <option value="WinWinD WWD-1-60">WinWinD WWD-1-60 (1000 Kw, 60 m)</option>
                <option value="Vestas V90 - 3.0 MW 109.4 dB(A)">Vestas V90 - 3.0 MW 109.4 dB(A) (3000 Kw, 90 m)</option>
            </select>
            <label className="block text-sm font-medium text-gray-700 mb-1">Factor Pérdidas </label>
            <input type="number" value={factorPerdidas} onChange={(e) => {
            // Agregamos una validación para asegurarnos de que el valor esté dentro del rango 0-90.
            const newValue = Math.min(1, Math.max(0, parseFloat(e.target.value))) || 0;
            setFactorPerdidas(newValue.toString());  // Convertimos el valor nuevamente a cadena.
            }}
            min="0"
            max="1"
            className="border border-secondary rounded w-full py-2 px-3" />

            <label className="block text-sm font-medium text-gray-700 mb-1">Altura Superficie </label>
            <input type="number" value={alturaSuperficie} onChange={(e) => setAlturaSuperficie(e.target.value)} className="border border-secondary rounded w-full py-2 px-3" />

        </div>
        </>
        )}

         {/* Columna 5 */}
         {fuente !== "viento" && (
            <>
        <div>
            <label className="block text-lg font-medium text-gray-700 mb-1">Fotovoltaico</label>
            <label className="block text-sm font-medium text-gray-700 mb-1">Tipo de Arreglo</label>
            <select value={tipoArreglo} onChange={(e) => setTipoArreglo(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2">
                <option value="hsat">HSAT</option>
                <option value="hor">Horizontal</option>
                <option value="tilt_2elev">2 inclinaciones por año</option>
                <option value="tilt">Inclinado</option>
                <option value="htsat">HTSAT</option>
                <option value="vsat">VSAT</option>
                <option value="tsat">TSAT</option>
                <option value="tracker_2axis">Seguimiento 2-ejes</option>
            </select>
            
            <label className="block text-sm font-medium text-gray-700 mb-1">Acimut</label>
            <select value={azimut} onChange={(e) => setAzimut(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2">
                <option value="0">0 = Norte</option>
                <option value="90">90 = Este</option>
                <option value="180">180 = Sur</option>
                <option value="270">270 = Oeste</option>
                
            </select>

            <label className="block text-sm font-medium text-gray-700 mb-1">Ángulo de elevación</label>
            <select value={anguloElevacion} onChange={(e) => setAnguloElevacion(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2">
                <option value="99">Óptimo a la latitud del sitio</option>
                <option value="90">90</option>
                <option value="70">70</option>
                <option value="45">45</option>
                <option value="15">15</option>
                <option value="0">0</option>
            </select>

            <label className="block text-sm font-medium text-gray-700 mb-1">Inclinación Máxima HSAT</label>
            <input type="number" value={inclinacionMaximaHSAT}   
            onChange={(e) => {
            // Agregamos una validación para asegurarnos de que el valor esté dentro del rango 0-90.
            const newValue = Math.min(90, Math.max(0, parseFloat(e.target.value))) || 0;
            setInclinacionMaximaHSAT(newValue.toString());  // Convertimos el valor nuevamente a cadena.
            }}
            min="0"
            max="90"
            className="border border-secondary rounded w-full py-2 px-3" />

            <label className="block text-sm font-medium text-gray-700 mb-1">Cobertura</label>
            <select value={cobertura} onChange={(e) => setCobertura(e.target.value)} className="border border-secondary rounded w-full py-2 px-3">
                <option value="glass">Glass</option>
                <option value="anti-reflectante">Anti-reflectante</option>
            </select>
           
            <label className="block text-sm font-medium text-gray-700 mb-1">Porcentaje Pérdidas</label>
            <input type="number" value={perdidas}  onChange={(e) => {
            // Agregamos una validación para asegurarnos de que el valor esté dentro del rango 0-90.
            const newValue = Math.min(100, Math.max(0, parseFloat(e.target.value))) || 0;
            setPerdidas(newValue.toString());  // Convertimos el valor nuevamente a cadena.
            }}
            min="0"
            max="100"
            className="border border-secondary rounded w-full py-2 px-3" />
        </div>
        </>
        )}
        
        {/* Columna 6 */}

            <div>
            <label className="block text-lg font-medium text-gray-700 mb-1">Baterías</label>
            <label className="block text-sm font-medium text-gray-700 mb-1">Ciclos</label>
            <input type="text" value={numeroCiclos} onChange={(e) => setNumeroCiclos(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2" />
            <label className="block text-sm font-medium text-gray-700 mb-1">Eficiencia (%)</label>
            <input type="text" value={eficienciaBateria} onChange={(e) => setEficienciaBateria(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2" />
            <label className="block text-sm font-medium text-gray-700 mb-1">Auto-Descarga (%/day)</label>
            <input type="text" value={selfDischarge} onChange={(e) => setSelfDischarge(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2" />
        </div>
      
    </div>


    </div>
    );

    
    export default Produccion;