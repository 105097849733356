import React from "react";



export const Costos =({hideHydrolysisResults,
    precioH2,
    setPrecioH2,
    precioH2O,
    setPrecioH2O,
    precioCo2,
    setPrecioCO2,
    precioelectricidad,
    setPrecioElectricidad,
    disponibilidad,
    setDisponibilidad,
    añosProyecto,
    setAñosProyecto,
    tasaDescuento,
    setTasaDescuento,
    
})=>{
    return(
        <div>
        <label className="block text-xl font-medium text-gray-700 mb-1">Costos</label>
        <hr className="my-2 border-secondary"/>

        <div className="grid grid-cols-3 gap-4 mb-6">

             {/* Columna 1 */}
             <div>
                <label className="block text-lg font-medium text-gray-700 mb-1">Entrada del proceso</label>
                <label className="block text-sm font-medium text-gray-700 mb-1">Precio H<sub>2</sub> (USD/kg)</label>
                <input type="text" value={precioH2} onChange={(e) => setPrecioH2(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2 " />
                
                <label className="block text-sm font-medium text-gray-700 mb-1">Precio H<sub>2</sub>O (USD/m³)</label>
                <input type="text" value={precioH2O} onChange={(e) => setPrecioH2O(e.target.value)} className="border border-secondary rounded w-full py-2 px-3 mb-2"/>
                <label className="block text-sm font-medium text-gray-700 mb-1">Precio CO<sub>2</sub> (USD/tonne)</label>
                <input type="text" value={precioCo2} onChange={(e) => setPrecioCO2(e.target.value)} className="border border-secondary rounded w-full py-2 px-3" />
                <label className="block text-sm font-medium text-gray-700 mb-1">Electricidad (USD/MWh)</label>
                <input type="text" value={precioelectricidad} onChange={(e) => setPrecioElectricidad(e.target.value)} className="border border-secondary rounded w-full py-2 px-3" />
                
            </div>
            {/* Columna 2 Costos*/}
            <div>
            <label className="block text-lg font-medium text-gray-700 mb-1"> Evaluación del proyecto</label>
            <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Disponibilidad (%)</label>
            <input type="text" value={disponibilidad} onChange={(e) => setDisponibilidad(e.target.value)} className="border border-secondary rounded w-full py-2 px-3" />
            </div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Años proyecto (años)</label>
            <input type="text" value={añosProyecto} onChange={(e) => setAñosProyecto(e.target.value)} className="border border-secondary rounded w-full py-2 px-3" />
            <label className="block text-sm font-medium text-gray-700 mb-1">Tasa Descuento (%)</label>
            <input type="text" value={tasaDescuento} onChange={(e) => setTasaDescuento(e.target.value)} className="border border-secondary rounded w-full py-2 px-3" />
            </div>  
        </div>
        
        </div>

    );
}