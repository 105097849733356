
import React from 'react';
import tuDocumentoPDF from '../assets/documentacion.pdf';
import Navbar from './Navbar';

function Documentation() {
    return (
        <>
        <Navbar />
        <div className="container mx-auto px-4 py-8">
            <div className="mb-4">
                <h1 className="text-lg font-semibold mb-2">Contacto</h1>
                <p>En caso de cualquier duda, contactar: 
                    <a href="mailto:joaarancibia@alumnos.uai.cl" className="text-blue-600 ml-2 hover:text-blue-800">
                        joaarancibia@alumnos.uai.cl
                    </a>
                    
                    <a href="mailto:javievargas@alumnos.uai.cl" className="text-blue-600 ml-4 hover:text-blue-800">
                        javievargas@alumnos.uai.cl
                    </a>
                </p>
            </div>

            <div>
                <h2 className="text-lg font-semibold mb-2">Documentación</h2>
                <a href={tuDocumentoPDF} download className="text-blue-600 hover:text-blue-800">
                    Descargar Documentación
                </a>
            </div>
        </div>
        </>
    );
}

export default Documentation;