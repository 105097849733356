import React from "react";
import logo from '../assets/logo4.png';
import logouai from '../assets/logo-uai.png';
import logo2 from '../assets/logo-greenfuel.png';

function Navbar() {
  return (
    <div className="bg-primary flex items-center">
      <header className="flex justify-between items-center w-full px-12 bg-primary">
        
        {/* Logo 2 en la esquina izquierda */}
        <div className="flex items-center">
          <a href="/" className="flex items-center">
            <img src={logo2} alt="Logo GreenFuel" className="w-40 h-32" />
          </a>
        </div>

        <div className="flex items-center gap-1 flex-1 justify-center">
          <a href="/" className="flex items-center">
            <img src={logouai} alt="Logo UAI" className="w-30 h-20" />
          </a>
          <a href="/" className="flex items-center">
            <img src={logo} alt="Logo efuel" className="w-30 h-20" />
          </a>
        </div>

        {/* Enlace a la página de documentación */}
        <a href="/documentacion" className="text-white">
          Documentación/Contacto
        </a>
      </header>
    </div>
  );
}

export default Navbar;

