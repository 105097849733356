import React, { useState } from 'react';
import { Radar, Line, Pie, Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, ArcElement, Tooltip, Legend } from 'chart.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas-pro';

// Registra los componentes de Chart.js
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, ArcElement, Tooltip, Legend);

const downloadPDF = () => {
    const input = document.getElementById('resultados');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
  
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
  
      pdf.save('data.pdf');
    });
};

const costBreakdownData = {
  labels: ['Materiales', 'Mano de Obra', 'Transporte', 'Otros'],
  datasets: [
    {
      label: 'Costo por kg de eFuel',
      data: [30, 20, 40, 10],
      backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)'],
      borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)'],
      borderWidth: 1,
    },
  ],
};

const cashFlowData = {
  labels: ['2022', '2023', '2024', '2025', '2026', '2027', '2028'],
  datasets: [
    {
      label: 'Ingresos Operacionales',
      data: [296583, 283772, 586529, 579386, 573841, 568352, 564399],
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgba(54, 162, 235, 1)',
    },
    {
      label: 'Costos Variables de Operación',
      data: [-89181, -137663, -137663, -137663, -137663, -137663, -137663],
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255, 99, 132, 1)',
    },
    {
      label: 'Costos Fijos',
      data: [-54487, -54487, -54487, -54487, -54487, -54487, -54487],
      backgroundColor: 'rgba(255, 206, 86, 0.2)',
      borderColor: 'rgba(255, 206, 86, 1)',
    },
    {
      label: 'EBITDA',
      data: [153015, 153015, 153015, 153015, 153015, 153015, 153015],
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
    },
    {
      label: 'Depreciación',
      data: [-539585, -539585, -539585, -539585, -539585, -539585, -539585],
      backgroundColor: 'rgba(153, 102, 255, 0.2)',
      borderColor: 'rgba(153, 102, 255, 1)',
    },
  ],
};

const RadarChart = ({ data }) => <Radar data={data} />;
const LineChart = ({ data }) => <Line data={data} />;
const PieChart = ({ data }) => <Pie data={data} />;
const BarChart = ({ data }) => <Bar data={data} />;
const DoughnutChart = ({ data }) => <Doughnut data={data} />;
const HorizontalBarChart = ({ data }) => (<Bar data={data} options={{ indexAxis: 'y' }} />);

const downloadData = () => {
  const data = {
    costBreakdownData,
    cashFlowData,
  };
  
  const json = JSON.stringify(data, null, 2);
  const blob = new Blob([json], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  
  const a = document.createElement('a');
  a.href = url;
  a.download = 'data.json';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const Resultados = ({
  areaState,
  paneles,
  aeros,
  solarMW,
  vientoMW,
  factorPlanta,
  resultado,
  h2o,
  co2,
  graph,
  jetfuelsinh,
  lcof_jetfuel_sinh,
  h2osinh,
  co2sinh,
  h2_sin_electrolisis,
  graphsinh,
  puntaje_ranking,
  porcentajes_zonificador,
  distanciaPuerto,
  distanciaFuentesCo2,
  distanciaMatriz,
  distanciaOleoductos,
  distanciaEstanquesComb,
  desviacion_estandar_factor_planta,
  arreglo_factor_planta_horario,
  arreglo_factor_planta_mensual,
  produccion_minima_mensual_jetfuel_sin_electrolisis,
  produccion_maxima_mensual_jetfuel_sin_electrolisis,
  desviacion_estandar_jetfuel_sin_electrolisis,
  arreglo_produccion_horaria_jetfuel_sin_electrolisis,
  arreglo_produccion_mensual_co2_sin_electrolisis,
  arreglo_produccion_horaria_co2_sin_electrolisis,
  arreglo_produccion_mensual_h2o_sin_electrolisis,
  arreglo_produccion_horaria_h2o_sin_electrolisis,
  arreglo_produccion_mensual_h2_sin_electrolisis,
  arreglo_produccion_horaria_h2_sin_electrolisis,
  factor_planta_sol,
  factor_planta_viento,
  porcentaje_solar,
  porcentaje_eolico,
  longitudState,
  latitudState,
  porcentajeOpexFijo,
  porcentajeOpexDirecto,
  porcentajeHidrogeno,
  porcentajeCO2,
  porcentajeElectricidad,
  porcentajeCapex,
  areaTerreno,
  cantidadPaneles,
  cantidadAerogeneradores,
  mwSolInstalados,
  mwVientoInstalados,
  bateriaCapacidad,
  potenciaReactor,
  energiaNoAlmacenadaAnual,
  porcentajeHorasAnualesReactorNoAlCien,
  porcentajeHorasAnualesSinEnergiaSuficienteReactor,

}) => {

  const [selectedDemandResource, setSelectedDemandResource] = useState('H2O');
  const [selectedProductionResource, setSelectedProductionResource] = useState('H2O');
  
  const changeDemandResource = (resource) => {
    setSelectedDemandResource(resource);
  };
  
  const changeProductionResource = (resource) => {
    setSelectedProductionResource(resource);
  };

  const radarData = {
    labels: ['Disponibilidad Energia Renovable', 'Viabilidad Ubicacion', 'Oportunidades de uso final', 'Almacenamiento', 'Ranking Total'],
    datasets: [
      {
        label: 'Puntajes',
        data: porcentajes_zonificador, // Usa porcentajes_zonificador para los datos
        backgroundColor: 'rgba(54, 212, 193, 0.2)',
        borderColor: '#36D4C1',
        borderWidth: 1,
      },
    ],
  };
  const distanceData = {
    labels: ['Oleoducto', 'Matriz Energetica','Fuente CO2', 'Puerto', 'Estanques Combustible'],
    datasets: [
      {
        label: 'Distancia Km',
        data: [distanciaOleoductos, distanciaMatriz, distanciaFuentesCo2, distanciaPuerto, distanciaEstanquesComb],
        fill: false,
        backgroundColor: 'rgba(54, 212, 193, 0.2)',
        borderColor: '#36D4C1',
      },
    ],
  };

  const monthlyFactorData = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo','Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre','Noviembre', 'Diciembre'],
    datasets: [
      {
        label: 'Factor Planta Mensual',
        data: arreglo_factor_planta_mensual,
        fill: false,
        backgroundColor: 'rgba(54, 212, 193, 0.2)',
        borderColor: '#36D4C1',
      },
    ],
  };

  const dailyFactorData = {
    labels: ['00:00', '1:00', '2:00', '3:00', '4:00','5:00', '6:00', '7:00', '8:00', '9:00','10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00','17:00', '18:00', '19:00', '20:00', '21:00', '22:00','23:00'],
    datasets: [
      {
        label: 'Factor Planta Horario',
        data: arreglo_factor_planta_horario,
        fill: false,
        backgroundColor: 'rgba(54, 212, 193, 0.2)',
        borderColor: '#36D4C1',
      },
    ],
  };

  const solarWindData = {
    labels: ['Solar', 'Eólico'],
    datasets: [
      {
        label: 'Energía',
        data: [porcentaje_solar, porcentaje_eolico],
        backgroundColor: ['rgba(255, 205, 86, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 205, 86, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const monthlyProductionData = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo','Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre','Noviembre', 'Diciembre'],
    datasets: [
      {
        label: 'Producción Promedio Mensual',
        data: graphsinh,
        fill: false,
        backgroundColor: 'rgba(54, 212, 193, 0.2)',
        borderColor: '#36D4C1',
      },
    ],
  };

  const horariaProductionData = {
    labels: ['00:00', '1:00', '2:00', '3:00', '4:00','5:00', '6:00', '7:00', '8:00', '9:00','10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00','17:00', '18:00', '19:00', '20:00', '21:00', '22:00','23:00'],
    datasets: [
      {
        label: 'Producción promedio por hora',
        data: arreglo_produccion_horaria_jetfuel_sin_electrolisis,
        fill: false,
        backgroundColor: 'rgba(54, 212, 193, 0.2)',
        borderColor: '#36D4C1',
      },
    ],
  };


  const getResourceData = (resource, isProduction) => {
    switch (resource) {
      case 'H2O':
        return {
          labels: isProduction ? ['00:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'] : ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          datasets: [
            {
              label: `${isProduction ? 'Producción de H₂O (m³ por hora)' : 'Demanda de H₂O (m³ por mes)'}`,
              data: isProduction ? arreglo_produccion_horaria_h2o_sin_electrolisis : arreglo_produccion_mensual_h2o_sin_electrolisis,
              fill: false,
              backgroundColor: 'rgba(153, 102, 255, 0.2)',
              borderColor: 'rgba(153, 102, 255, 1)',
            },
          ],
        };
      case 'CO2':
        return {
          labels: isProduction ? ['00:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'] : ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          datasets: [
            {
              label: `${isProduction ? 'Producción de CO₂ (ton por hora)' : 'Demanda de CO₂ (ton por mes)'}`,
              data: isProduction ? arreglo_produccion_horaria_co2_sin_electrolisis : arreglo_produccion_mensual_co2_sin_electrolisis,
              fill: false,
              backgroundColor: 'rgba(255, 159, 64, 0.2)',
              borderColor: 'rgba(255, 159, 64, 1)',
            },
          ],
        };
      case 'H2':
        return {
          labels: isProduction ? ['00:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'] : ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          datasets: [
            {
              label: `${isProduction ? 'Producción de H₂ (ton por hora)' : 'Demanda de H₂ (ton por mes)'}`,
              data: isProduction ? arreglo_produccion_horaria_h2_sin_electrolisis : arreglo_produccion_mensual_h2_sin_electrolisis,
              fill: false,
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
            },
          ],
        };
      default:
        return {};
    }
  };
  
  const demandData = getResourceData(selectedDemandResource, false);
  const productionData = getResourceData(selectedProductionResource, true);

  const percentCapitalCostData = {
    labels: ['Opex Fijo', 'Opex Directo', 'Hidrogeno', 'CO₂','Electricidad','Capex'],
    datasets: [
      {
        label: 'Porcentaje Capital Cost Breakdown',
        data: [porcentajeOpexFijo, porcentajeOpexDirecto, porcentajeHidrogeno, porcentajeCO2, porcentajeElectricidad, porcentajeCapex],
        backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(153, 102, 255, 0.2)'],
        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(153, 102, 255, 1)'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div id="resultados" className="relative p-4">
      <div className="p-4">
        <button 
          className="absolute top-4 right-4 px-4 py-2 bg-secondary text-black rounded" 
          onClick={downloadPDF}
        >
          Descargar Resultados
        </button>
        <h2 className="text-2xl font-bold mb-2">Resultados de producción Jet-Fuel</h2>
        <hr className="mb-4" />
      </div>
      <section className="p-4">
        <h2 className="text-xl font-bold border-b-4 border-secondary inline-block">Zonificador</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <h3 className="text-lg font-semibold">Ranking Ubicación</h3>
            <p className="text-3xl">{puntaje_ranking} / 100</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold">Evaluación Multicriterio</h3>
            <RadarChart data={radarData} />
          </div>
          <div>
            <h3 className="text-lg font-semibold">Distancias</h3>
            <HorizontalBarChart data={distanceData} />
          </div>
        </div>
      </section>

      <section className="p-4 bg-gray-100">
        <h2 className="text-xl font-bold  border-b-4 border-secondary inline-block">Instalación</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div><h3 className="text-lg font-semibold">Longitud °</h3><p className="text-3xl">{Number(longitudState).toFixed(2)}</p></div>
          <div><h3 className="text-lg font-semibold">Latitud °</h3><p className="text-3xl">{Number(latitudState).toFixed(2)}</p></div>
          <div><h3 className="text-lg font-semibold">Terreno</h3><p className="text-3xl">{areaState.toLocaleString()}<span className="text-2xl"> m²</span></p></div>
          <div><h3 className="text-lg font-semibold">Cantidad Aerogeneradores</h3><p className="text-3xl">{aeros}</p></div>
          <div><h3 className="text-lg font-semibold">Cantidad Paneles</h3><p className="text-3xl">{paneles.toLocaleString()}</p></div>
          <div><h3 className="text-lg font-semibold">Potencia Sol Instalada</h3><p className="text-3xl">{solarMW}<span className="text-2xl"> MW</span></p></div>
          <div><h3 className="text-lg font-semibold">Potencia Viento Instalada</h3><p className="text-3xl">{vientoMW}<span className="text-2xl"> MW</span></p></div>
          <div><h3 className="text-lg font-semibold">Factor Planta Anual</h3><p className="text-3xl">{factorPlanta}</p></div>
          <div><h3 className="text-lg font-semibold">Desviación Estándar Factor Planta</h3><p className="text-3xl">{desviacion_estandar_factor_planta}</p></div>
          <div><h3 className="text-lg font-semibold">Factor Planta Sol</h3><p className="text-3xl">{factor_planta_sol}</p></div>
          <div><h3 className="text-lg font-semibold">Factor Planta Viento</h3><p className="text-3xl">{factor_planta_viento}</p></div>
          <div><h3 className="text-lg font-semibold">Capacidad Bateria</h3><p className="text-3xl">{bateriaCapacidad}</p></div>
          <div><h3 className="text-lg font-semibold">Potencia Reactor</h3><p className="text-3xl">{potenciaReactor}</p></div>
          <div><h3 className="text-lg font-semibold">Energia No Almecenada Anual (Perdidas)</h3><p className="text-3xl">{energiaNoAlmacenadaAnual}</p></div>
          <div><h3 className="text-lg font-semibold">Porcentaje Horas anuales reactor no funciona al cien</h3><p className="text-3xl">{porcentajeHorasAnualesReactorNoAlCien}</p></div>
          <div><h3 className="text-lg font-semibold">Porcentaje Horas Anuales sin energia para el reactor</h3><p className="text-3xl">{porcentajeHorasAnualesSinEnergiaSuficienteReactor}</p></div>

        </div>
      </section>

      <section className="p-4 ">
        <h2 className="text-xl font-bold  border-b-4 border-secondary inline-block">Producción eFuel</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="col-span-1">
            <div><h3 className="text-lg font-semibold">Desviación Estándar Producción</h3><p className="text-3xl">{desviacion_estandar_jetfuel_sin_electrolisis.toLocaleString()}<span className="text-2xl"> m³</span></p></div>
            <div><h3 className="text-lg font-semibold">Producción Mínima Mensual</h3><p className="text-3xl">{produccion_minima_mensual_jetfuel_sin_electrolisis.toLocaleString()}<span className="text-2xl"> m³</span></p></div>
            <div><h3 className="text-lg font-semibold">Producción Máxima Mensual</h3><p className="text-3xl">{produccion_maxima_mensual_jetfuel_sin_electrolisis.toLocaleString()}<span className="text-2xl"> m³</span></p></div>
            <div><h3 className="text-lg font-semibold">Total eFuel Anual</h3><p className="text-3xl">{jetfuelsinh.toLocaleString()} <span className="text-2xl"> m³</span></p></div>
          </div>
          <div className="col-span-2">
            <div className="grid grid-cols-2 gap-4">
              <div><h3 className="text-lg font-semibold">Producción Promedio Mensual</h3><BarChart data={monthlyProductionData} /></div>
              <div><h3 className="text-lg font-semibold">Producción Promedio Hora</h3><LineChart data={horariaProductionData} /></div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-4 bg-gray-100">
  <h2 className="text-xl font-bold border-b-4 border-secondary inline-block">Insumos</h2>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    <div className="col-span-1">
      <div><h3 className="text-lg font-semibold">Total H<sub>2</sub>O Anual</h3><p className="text-3xl">{h2osinh.toLocaleString()}<span className="text-2xl"> m³</span></p></div>
      <div><h3 className="text-lg font-semibold">Total CO<sub>2</sub> Anual</h3><p className="text-3xl">{co2sinh.toLocaleString()}<span className="text-2xl"> tonne</span></p></div>
      <div><h3 className="text-lg font-semibold">Total H<sub>2</sub> Anual</h3><p className="text-3xl">{h2_sin_electrolisis.toLocaleString()}<span className="text-2xl"> tonne</span></p></div>
    </div>
    <div className="col-span-1">
      <h3 className="text-lg font-semibold">Demanda Mensual</h3>
      <div className="flex space-x-2 mb-2">
        <button onClick={() => changeDemandResource('H2O')} className={`text-black ${selectedDemandResource === 'H2O' ? 'font-bold' : ''}`}>H₂O</button>
        <button onClick={() => changeDemandResource('CO2')} className={`text-black ${selectedDemandResource === 'CO2' ? 'font-bold' : ''}`}>CO₂</button>
        <button onClick={() => changeDemandResource('H2')} className={`text-black ${selectedDemandResource === 'H2' ? 'font-bold' : ''}`}>H₂</button>
      </div>
      <LineChart data={demandData} />
    </div>
    <div className="col-span-1">
      <h3 className="text-lg font-semibold">Producción Horaria</h3>
      <div className="flex space-x-2 mb-2">
        <button onClick={() => changeProductionResource('H2O')} className={`text-black ${selectedProductionResource === 'H2O' ? 'font-bold' : ''}`}>H₂O</button>
        <button onClick={() => changeProductionResource('CO2')} className={`text-black ${selectedProductionResource === 'CO2' ? 'font-bold' : ''}`}>CO₂</button>
        <button onClick={() => changeProductionResource('H2')} className={`text-black ${selectedProductionResource === 'H2' ? 'font-bold' : ''}`}>H₂</button>
      </div>
      <LineChart data={productionData} />
    </div>
  </div>
</section>

      <section id="costos-section" className="p-4 ">
        <h2 className="text-xl font-bold  border-b-4 border-secondary inline-block">Costos</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="col-span-1">
            <div><h3 className="text-lg font-semibold">Costo eFuel</h3><p className="text-3xl">{lcof_jetfuel_sinh.toLocaleString()}<span className="text-2xl"> USD/m³</span></p></div>
          </div>
          <div className="col-span-2">
            <div className="grid grid-cols-2 gap-4">
            <div><h3 className="text-lg font-semibold">Descomposición de costos (%) </h3><DoughnutChart data={percentCapitalCostData} /></div>
              <div><h3 className="text-lg font-semibold">Descomposición del Costo del kg de eFuel MOCKUP</h3><BarChart data={costBreakdownData} /></div>
            </div>
            <div><h3 className="text-lg font-semibold mt-4">Flujo de Caja MOCKUP</h3><BarChart data={cashFlowData} /></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Resultados;

