import React from "react";



function Footer(){
    return(
      <footer className="w-full bg-primary p-2">
   
    <p className="block text-center font-sans text-base font-normal leading-relaxed text-secondary antialiased">
      ©2023 xestudiantesUAI
    </p>
  </footer>
    );
  }

  export default Footer;